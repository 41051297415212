import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import { getTokens } from '@/utils/auth';
export default {
  name: 'MyExportExcel',
  props: {
    filename: {
      type: String
    },
    list: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    labelArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    valueArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 微调数据 参考staff-list.vue
    fixData: {
      type: Function,
      default: function _default(key, val) {
        return val;
      }
    },
    //导出弹窗的类型、是否需要弹窗
    // 默认不弹出弹窗为0，弹窗选择内容 原引入index为1；index1为2，teachIndex为3
    dialogType: {
      type: [String, Number],
      default: 0
    },
    screenE: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      label: this.labelArr,
      filterVal: this.valueArr,
      dialogTableVisible: false,
      downloadLoading: false,
      cids: [],
      type: null,
      url: '',
      data: {},
      title: '',
      // 是否校验选择数据 0为校验 1为不校验
      isVaildData: 0
    };
  },
  computed: {
    keyValueObj: function keyValueObj() {
      var _this = this;
      return this.labelArr.reduce(function (acc, cur, curI) {
        acc[cur] = _this.valueArr[curI];
        return acc;
      }, {});
    }
  },
  watch: {
    label: function label(newV) {
      var _this2 = this;
      this.filterVal = newV.map(function (e) {
        return _this2.keyValueObj[e];
      });
    }
  },
  methods: {
    propDataFun: function propDataFun(url, data, title) {
      this.url = url;
      this.data = data;
      this.title = title;
      // url是接口地址
      // data是参数
      if (this.dialogType == 0) {
        this.handleDownload();
      } else {
        this.dialogTableVisible = true;
        this.cids = [];
        if (this.list.length) {
          if (this.dialogType == 1) {
            for (var i = 0; i < this.list.length; i++) {
              this.cids.push(this.list[i].customer_id);
            }
          } else if (this.dialogType == 2) {
            for (var i = 0; i < this.list.length; i++) {
              this.cids.push(this.list[i].id);
            }
          }
          this.dialogTableVisible = true;
        } else if (this.isVaildData == 0) {
          this.$message({
            message: '请选择需要导出的对象',
            type: 'warning'
          });
        }
      }
    },
    handleDownload: function handleDownload() {
      var _this3 = this;
      var url = this.url;
      var data = this.data;
      var title = this.title;
      var params = {};
      if (this.dialogType == 1) {
        this.dialogTableVisible = false;
        params = {
          ids: String(this.cids),
          fields: this.filterVal.toString()
        };
      } else if (this.dialogType == 3) {
        params = {
          stime: this.screenE.creationDateName[0],
          etime: this.screenE.creationDateName[1],
          popularize_platform_ids: this.screenE.advert_id.toString(),
          create_ids: this.screenE.PlatformId.toString(),
          operate_type: this.screenE.modes.toString(),
          field_list: this.filterVal.toString()
        };
      } else {
        params = _objectSpread({}, data);
      }
      this.downloadLoading = true;
      axios.defaults.headers.common['token'] = getTokens();
      axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      axios.post(process.env.VUE_APP_BASE_API2 + "/clientserver.php/".concat(url), params, {
        responseType: 'blob'
      }).then(function (res) {
        _this3.downloadExcel(res, title);
      });
      this.downloadLoading = false;
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    formatJson: function formatJson(filterVal, jsonData) {
      var _this4 = this;
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          return _this4.fixData(j, v[j]);
        });
      });
    }
  }
};